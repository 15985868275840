/*
  __  __   ______   _____    _____   _    _   __  __ 
 |  \/  | |  ____| |  __ \  |_   _| | |  | | |  \/  |
 | \  / | | |__    | |  | |   | |   | |  | | | \  / |
 | |\/| | |  __|   | |  | |   | |   | |  | | | |\/| |
 | |  | | | |____  | |__| |  _| |_  | |__| | | |  | |
 |_|  |_| |______| |_____/  |_____|  \____/  |_|  |_|                                                                                                  
    __   ___   ___              ___   ___   ___   
   / /  / _ \ / _ \            / _ \ / _ \ / _ \  
  / /_ | | | | | | |  ______  | (_) | | | | | | | 
 | '_ \| | | | | | | |______|  > _ <| | | | | | | 
 | (_) | |_| | |_| |          | (_) | |_| | |_| | 
  \___/ \___/ \___/            \___/ \___/ \___/  
                                               
*/
@media screen and (max-width: 374px) and (max-height:599px){
    /* Your CSS styles for screens over 1600px */



/* _    _    ____    __  __   ______ 
  | |  | |  / __ \  |  \/  | |  ____|
  | |__| | | |  | | | \  / | | |__   
  |  __  | | |  | | | |\/| | |  __|  
  | |  | | | |__| | | |  | | | |____ 
  |_|  |_|  \____/  |_|  |_| |______| */

  .home-wrap{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height:100vh;
    position:absolute;
}


.background-banner1 {
    position: relative; /* Keeps the image fixed in place while scrolling */
    width: 100%; /* Allows the width to adjust automatically */
    height: 100vh; /* Ensures the image covers the full height of the viewport */
    background-size: cover; /* Ensures the image covers the container */
    background-position: center; /* Keeps the left side always in view */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    z-index: -1; /* Places the background behind other content */
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    animation: fadeIn 2s ease-in forwards;
    border-bottom: 10px solid black;
  }

  .main-title{
    color:white;
    position:absolute;
    font-size:180px;
    font-weight:700;
    letter-spacing:4px;
    margin-top:34px;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.75);
    transition: all 0.3s ease;
}
.main-title.scrolled{
    opacity:0;
    visibility: hidden;
}
.main-title img{
  max-height:54vh;
  max-width:80vw;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }



/* Menu.css */
.menu-title{
    color:white;
    position:absolute;
    margin-top:-10px;
    height:100%;
    max-width:30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.menu-title img{
    max-width:100%;
}
.menu-number{
    color:white;
    position:absolute;
    height:100%;
    right:15px;
    top:0px;
    font-size:24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.menu-full-length {
    position: fixed;
    width:100%;
    max-width:100vw;
    padding: 10px;
    box-sizing: border-box;
    z-index: 900;
    transition: all 1s ease;
    height:60px;

  }

  .menu-full-length.scrolled {
    background-color:black;
  }
  .menu-full-length.unscrolled {
    opacity:0;
    visibility:hidden;
  }

.menu-container {
    position: fixed;
    top: 0;
    right: 0;
    padding: 10px;
    z-index: 1000;
    transition: all 0.3s ease;
  }
  
  .menu-container.scrolled {
    top: 10px;
    right: 10px;
  }

  .menu-container.unscrolled {
    top: 10px;
    right: 10px;
    opacity:0;
    visibility:hidden;
  }
  
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
  }
  
  .hamburger .line {
    width: 100%;
    height: 3px;
    background-color: #bfbfbf;
    border-radius: 3px;
  }
  
  .menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
  }
  
  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .menu ul li {
    margin: 10px 0;
  }
  
  .menu ul li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
  }
  
  .menu ul li a:hover {
    color: #007bff;
  }













.section3{
    position:relative;
    min-height:100vh;
    width:100%;
    max-width:100%;
    background: linear-gradient(to bottom, #f0faff, #ffffff);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom:50px;
}
.section3 .Asset2{
    position:absolute;
    bottom:0px;
    right:0px;
    width:100%;
    z-index:1;

}
.section3 .pricing-row{
    max-width:95%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index:100;
}
.section3 .pricing-row .price{
    width:80vw;
    max-height:500px;
    min-height:320px;
    height:40vh;
    background:black;
    border-radius:10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
    padding:10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(50px); /* Start slightly below */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.section3 .price.animate{
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to its original position */
}
.section3 .pricing-row .price .smaller-dollar-span{
  font-size:40px;
  vertical-align:text-top;
}
.section3 .pricing-row .price .price-title{
    color:white;
    font-size:50px;
    text-align: center;
    flex-grow:1;
}
.section3 .pricing-row .price .pricing-details-wrap{
  flex-grow:2;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section3 .pricing-row .price .price-price{
    color:white;
    font-size:160px;
    line-height:150px;
    text-align: center;
    position:relative;
}
.section3 .pricing-row .price .price-panel{
    color:white;
    font-size:30px;
    text-align: center;
}

.section3 .pricing-row .spacer{
    height:30px;
}





.section2{
  position:relative;
  min-height:90vh;
  width:100%;
  background: linear-gradient(to bottom, #cae9ff, #f0faff);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section2-title{
    margin-block-start: 50px;
    font-size:40px;
    font-weight:500;
    color:white;
    -webkit-text-stroke: 2px #646464; /* for WebKit browsers */
    text-stroke: 2px #646464;
    -webkit-text-fill-color: white; /* for WebKit browsers */
    text-fill-color: white;   
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.4); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
    text-align:center;
}
.section3-title{
    margin-block-start: 50px;
    margin-block-end: 50px;
    font-size:100px;
    font-weight:500;
    color:white;
    -webkit-text-stroke: 2px #383838; /* for WebKit browsers */
    text-stroke: 2px #383838;
    -webkit-text-fill-color: white; /* for WebKit browsers */
    text-fill-color: white;
    z-index:10;
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.4); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
}



.triple-row1{
    width:96%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-block-start: 65px;
    z-index:10;
    max-width:1500px;
}
.triple-row1 .item.animate{
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to its original position */
}
.triple-row1 .item{
    width:80vw;
    max-width:450px;
    font-size:24px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius:10px;
    background:rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
    position:relative;
    margin-block-end:50px;
    opacity: 0;
    transform: translateY(50px); /* Start slightly below */
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.triple-row1 .item .item-image{
  align-self:flex-start;
    max-width:450px;
    width:80vw;
    max-height: 450px;;
    height:80vw;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-size: cover; /* Ensures the image covers the container */
    background-position: center; /* Keeps the left side always in view */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    flex-shrink:0;
}

.triple-row1 .item .item-img1{
  background-position: center; /* Keeps the left side always in view */
}

.triple-row1 .item .item-img2{
  background-position: center; /* Keeps the left side always in view */
}
.triple-row1 .item .item-img3{
  background-position: center; /* Keeps the left side always in view */
}

.floating-div {
    width: 200px; /* Set width as needed */
    height: 200px; /* Set height as needed */
    background-color: #fff; /* Background color of the div */
    border-radius: 8px; /* Rounded corners if desired */
}
.triple-row1 .item .text-wrap{
    flex-grow:1;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}
.triple-row1 .item .title{
    font-size:40px;
    font-weight:600;
    margin-block-end: 5 0px;
    width:100%;
    text-align: center;
    letter-spacing: 1px;
}
.triple-row1 .item img{
    max-width:180px;
    position:absolute;
    top:-75px;
    left:-15px;
    display:none;
}
.triple-row1 .item .item-text{
    padding:10px 20px 20px;
    font-size:18px;
    text-align: justify;
}
.triple-row1 .vertical-divider{
    height:8px;
}
.Asset1{
    position:absolute;
    bottom:0px;
    right:0px;
    width:100%;
    z-index:1;
}




}
