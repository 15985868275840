

@font-face {
    font-family: 'Bebas';
    src: url('./../../public/fonts/BebasNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  .Bebas{
    font-family: 'Bebas', sans-serif;
  }
  
  .Urbanist {
    font-family: 'Urbanist', sans-serif;
  }
  
  
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  /* Italic styles */
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('./../../public/fonts/Urbanist/static/Urbanist-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  